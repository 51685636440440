table{
    width:100%;
    table-layout: fixed;
    overflow-y:auto;
    border-radius: 10px;
    background-color: rgba(75, 56, 56, 0.126);
  }
  .tbl-header{
    background-color: rgba(255, 255, 255, 0.126);
    backdrop-filter: blur(10px); 
   }
  .tbl-content{
    height:500px;
    overflow-x:auto;
    overflow-y:auto;
    backdrop-filter: blur(10px);
    margin-top: 0px;
    border: 1px solid rgb(0, 0, 0);
   
  }
  .tr:hover {
    background-color: #1a191989;  
}
  .tooltip {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.8);
    color: rgb(30, 26, 26);
    padding: 5px;
    border-radius: 3px;
    font-size: 12px;
    backdrop-filter: blur(5px);
}
  th{
    padding: 20px 15px;
    text-align: left;
    font-weight: 500;
    font-size: 16px;
    color: black;
    text-transform: uppercase;
    border: solid 2px rgb(0, 0, 0);
  }
  td{
    padding: 15px;
    text-align: left;
    vertical-align:middle;
    font-weight: 300;
    font-size: 15px;
    color: rgb(0, 0, 0);
    border-bottom: solid 2px rgb(0, 0, 0);
  }
  
  
.logo_list {
  width: 30px;
  height: 30px;
  margin-right: 8px; 
  border-radius: 90%;
  border: 1px solid whitesmoke;
}

.show-more {
  color: rgba(138, 7, 31, 0.804);
  cursor: pointer;
  text-decoration: underline;
}

.full-description-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
}

.full-description-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  max-width: 500px;
  max-height: 400px;
  overflow: auto;
}

.close {
  position: absolute;
  right: 20px;
  top: 20px;
  font-size: 20px;
  cursor: pointer;
}
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.loader {
  border: 8px solid #10600c;
  border-top: 8px solid #0b0b0b;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}
  
  @import url(https://fonts.googleapis.com/css?family=Roboto:400,500,300,700);

  section{
  margin-top: 130px;
  }
  
  
  ::-webkit-scrollbar {
      width: 6px;
  } 
  ::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
  } 
  ::-webkit-scrollbar-thumb {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
  }

  @media (max-width: 768px) {
    .tbl-header,
    .tbl-content,
    th,
    td {
      font-size: 14px;
    }
  
    th,
    td {
      padding: 10px;
    }
  
    .full-description-content {
      max-width: 100%;
      max-height: 300px;
    }
  }
  
 
  @media (max-width: 480px) {
    .tbl-header,
    .tbl-content,
    th,
    td {
      font-size: 12px;
    }
  
    th,
    td {
      padding: 8px;
    }
  
    .full-description-content {
      max-width: 100%;
      max-height: 200px;
    }
  }